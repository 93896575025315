<template>
  <div>
    <h1>정산목록</h1>
    <p>정산 목록을 조회할 수 있습니다.</p>
  </div>
</template>

<script>
export default {
  name: "AccountingList",
};
</script>
