<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_74.png" alt="" />
      <span>{{ menuTitle }}</span>
    </div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="item in items" :key="item.name">
          <router-link :to="item.link" :class="{ on: isActive(item.link) }">{{
            item.name
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    menuTitle() {
      // 현재 경로에 따라 메뉴명을 설정
      if (this.$route.path.includes("/projects")) {
        return "공사관리"; // 공사 관련 경로일 때
      } else if (this.$route.path.includes("/orders")) {
        return "수주관리"; // 수주 관련 경로일 때
      } else if (this.$route.path.includes("/accounting")) {
        return "정산관리"; // 정산 관련 경로일 때
      } else if (this.$route.path.includes("/managers")) {
        return "담당자관리"; // 담당자 관련 경로일 때
      } else {
        return "내 정보 관리"; // 기본 메뉴명
      }
    },
  },
  methods: {
    isActive(link) {
      // 공사 목록, 공사 등록, 공사 상세 페이지에서 공사목록이 활성화되도록 처리
      const currentPath = this.$route.path;

      if (this.$route.path.includes("/projects")) {
        return (
          currentPath.startsWith("/projects/list") || // 공사목록 페이지
          currentPath.startsWith("/projects/add") || // 공사등록 페이지
          currentPath.startsWith("/projects/view") || // 공사상세 페이지
          (currentPath.startsWith("/projects/companyview") && // 공사상세(업체) 페이지
            link === "/projects/list")
        );
      }

      if (this.$route.path.includes("/managers")) {
        return (
          currentPath.startsWith("/managers/list") || // 담당자목록 페이지
          currentPath.startsWith("/managers/add") || // 담당자등록 페이지
          (currentPath.startsWith("/managers/view") && // 담당자상세 페이지
            link === "/managers/list")
        );
      }

      if (this.$route.path.includes("/accounting")) {
        return (
          //currentPath.startsWith("/accounting/list") || // 정산목록 페이지
          currentPath.startsWith("/accounting/list") && //
          link === "/accounting/list"
        );
      }

      // 수주 관련 경로 처리
      if (currentPath.startsWith("/orders/list")) {
        return link === "/orders/list";
      }

      // 나의 수주내역 처리
      if (currentPath.startsWith("/orders/self")) {
        return link === "/orders/self";
      }

      if (this.$route.path.includes("/myinfo")) {
        return (
          currentPath.startsWith("/myinfo/view") && // 공사상세(업체) 페이지
          link === "/myinfo/view"
        );
      }
    },
  },
};
</script>

<style scoped></style>
