<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공사 상세보기</div>
        <div class="description">
          공사건을 조회할 수 있습니다. <span class="text_red"></span>
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">공사상태</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>상태</th>
                  <td>
                    <div :class="getStatusClass(this.status)">
                      <span>{{ getStatusText(this.status) }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="status === 'F'">
                  <th>철회 일시</th>
                  <td>{{ withDrawalDtm }}</td>
                </tr>
                <tr v-if="status === 'F'">
                  <th>철회 사유</th>
                  <td>{{ withDrawalMemo }}</td>
                </tr>
                <tr v-if="status === 'C'">
                  <th>취소 일시</th>
                  <td>{{ cancelDtm }}</td>
                </tr>
                <tr v-if="status === 'C'">
                  <th>취소 사유</th>
                  <td>{{ cancelMemo }}</td>
                </tr>
              </tbody>
            </table>
            <div class="part_title">공사정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>프로젝트명<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="프로젝트명을 입력해주세요"
                      v-model="projectName"
                      ref="projectName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>공사금액(vat별도)<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250 amount-input"
                      placeholder="공사금액을 입력해주세요"
                      v-model="formattedRealAmount"
                      ref="formattedRealAmount"
                      @input="formatRealAmount"
                    />
                  </td>
                </tr>
                <tr>
                  <th>추가금액(vat별도)</th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250 amount-input"
                        placeholder="추가금액을 입력해주세요"
                        v-model="formattedExtraAmount"
                        ref="formattedExtraAmount"
                        @input="formatExtraAmount"
                      />
                      * 추가금액은 입력 되었을 경우만 외주 업체들이 확인 할 수
                      있습니다.
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select
                        class="w_250"
                        v-model="selectedArea"
                        @change="handleAreaChange"
                      >
                        <option value="0">전체보기</option>
                        <option
                          v-for="area in areas"
                          :key="area.id"
                          :value="area.id"
                        >
                          {{ area.areaName }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공사시작일<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedStartDate"
                        @change="addStartDate"
                      />
                      <!-- 라디오 버튼 리스트 -->
                      <label
                        v-for="(date, index) in selectedStartDates"
                        :key="index"
                        :style="{
                          color: choiceSelDate === date ? 'red' : 'black', // 동일한 값일 경우 빨간색
                          fontWeight:
                            choiceSelDate === date ? 'bold' : 'normal', // 동일한 값일 경우 굵게 표시
                        }"
                      >
                        <input
                          type="radio"
                          :value="date"
                          v-model="choiceSelDate"
                        />
                        {{ date }} ({{ getDayOfWeek(date) }})
                        <!-- 업체가 선택한 날짜에만 '(업체선택)' 추가 -->
                        <span v-if="choiceSelDate === date"
                          >(업체선택완료)</span
                        >
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>공사상세내용</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="상세설명을 입력하세요. "
                        v-model="detailDesc"
                        ref="detailDesc"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>투입자재목록<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <!-- 자재 선택 dropdown -->
                      <select
                        v-model="selectedMaterial"
                        class="w_250"
                        @change="addMaterial"
                      >
                        <option value="">자재를 선택하세요</option>
                        <option
                          v-for="material in materials"
                          :key="material.id"
                          :value="material"
                        >
                          {{
                            material.materialsName +
                            (material.modelName
                              ? " (" + material.modelName + ")"
                              : "")
                          }}
                        </option>
                      </select>
                    </div>
                    <!-- 선택된 자재 목록 -->
                    <div
                      v-for="(material, index) in selectedMaterials"
                      :key="index"
                      class="material-item"
                    >
                      <span>{{
                        material.materialsName +
                        (material.modelName
                          ? " (" + material.modelName + ")"
                          : "")
                      }}</span>
                      <button type="button" @click="decreaseQuantity(index)">
                        -
                      </button>
                      <span>{{ material.quantity }}</span>
                      <button type="button" @click="increaseQuantity(index)">
                        +
                      </button>
                      <button type="button" @click="removeMaterial(index)">
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>
                    <div class="inner_td">
                      <textarea
                        placeholder="내용을 입력하세요. "
                        v-model="addMemo"
                        ref="addMemo"
                      ></textarea>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (영업)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="salesmanager in salesManagers"
                        :key="salesmanager.userId"
                        type="button"
                        :class="{
                          on: selectedSalesManager === salesmanager.userId,
                        }"
                        @click="selectSalesManager(salesmanager.userId)"
                      >
                        {{ salesmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>담당자 (PM)<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <button
                        v-for="pmmanager in pmManagers"
                        :key="pmmanager.userId"
                        type="button"
                        :class="{ on: selectedPmManager === pmmanager.userId }"
                        @click="selectPmManager(pmmanager.userId)"
                      >
                        {{ pmmanager.adminName }}
                      </button>
                    </div>
                  </td>
                </tr>
                <!-- 공사건 상태 (수주전-'N'/수주완료-'Y'/공사완료-'YY'/계산서발급-'B'/수주철회-'F'/수주취소-'C') -->
                <!-- <tr>                  
                  <th>공사상태</th>
                  <td>
                    <div class="inner_td">
                      <button>공사완료</button>
                      <button>계산서발급</button>
                      <button>수주철회</button>
                      <button>수주취소</button>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">
              고객사 정보 (해당 부분은 수주한 업체에만 보이는 정보입니다.)
            </div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <!-- 고객사 상호 // 수주한 사람한테만 보임 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>고객사 상호<span class="text_red">*</span></th>
                  <td>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="고객사 상호명을 입력해주세요"
                      v-model="customerName"
                      ref="customerName"
                    />
                  </td>
                </tr>
                <!-- 상세주소 // 수주한 사람한테만 보임 > 수주하기전에도 칸은 표시하고 “수주 후에 확인 가능” 이라고 표시하기 -->
                <tr>
                  <th>상세주소<span class="text_red">*</span></th>
                  <td class="td_address">
                    <div>
                      <input
                        type="text"
                        class="w_150"
                        placeholder="우편번호"
                        v-model="postalCode"
                        ref="postalCode"
                        readonly
                      />
                      <button
                        type="button"
                        class="keep"
                        @click="openPostcodePopup"
                      >
                        주소찾기
                      </button>
                    </div>
                    <input
                      type="text"
                      class="w_250"
                      placeholder="주소"
                      v-model="address"
                      ref="address"
                    />
                    <input
                      type="text"
                      class="w_250"
                      placeholder="상세주소"
                      v-model="detailedAddress"
                      ref="detailedAddress"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="status !== 'N'" class="tbl_search tbl_info">
            <div class="part_title">공사 담당자 정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" />
                <col />
              </colgroup>
              <tbody>
                <tr
                  v-if="
                    status === 'Y' ||
                    status === 'YY' ||
                    status === 'B' ||
                    status == 'C'
                  "
                >
                  <th>수주 업체명</th>
                  <td>
                    <span>{{ choiceCompanyName || "데이터 없음" }}</span>
                  </td>
                </tr>
                <tr
                  v-if="
                    status === 'Y' ||
                    status === 'YY' ||
                    status === 'B' ||
                    status == 'C'
                  "
                >
                  <th>수주 일시</th>
                  <td>
                    <span>{{ choiceDtm || "데이터 없음" }}</span>
                  </td>
                </tr>
                <!-- 총 투입 예정 인원수 -->
                <tr>
                  <th>총 투입 예정 인원수<span class="text_red">*</span></th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{
                        totalPersonnel ? totalPersonnel + " 명" : "데이터 없음"
                      }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 투입 인원 공사 담당자 이름 -->
                <tr>
                  <th>공사 담당자<span class="text_red">*</span></th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ representativeName || "데이터 없음" }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 연락처 -->
                <tr>
                  <th>공사 담당자 연락처<span class="text_red">*</span></th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ representativeContact || "데이터 없음" }}</span>
                    </div>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr v-if="status === 'YY'">
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- selectedBillDate 값이 있으면 문구로 표시 -->
                    <div v-if="selectedBillDate">
                      <span>{{ selectedBillDate }}</span>
                    </div>
                    <!-- 값이 없으면 입력 필드로 표시 -->
                    <div v-else>
                      <input
                        type="date"
                        class="w_250"
                        v-model="selectedBillDate"
                      />
                    </div>
                    <button
                      v-if="selectedBillDate && userPermission === 'M'"
                      type="button"
                      class="keep"
                      @click="billStatusConstruction"
                    >
                      계산서 발급 확인
                    </button>
                  </td>
                </tr>
                <!-- 세금계산서 발급일 -->
                <tr v-if="status === 'B'">
                  <th>세금계산서 발급일<span class="text_red">*</span></th>
                  <td>
                    <!-- status가 'Y'일 경우 텍스트로 표시 -->
                    <div>
                      <span>{{ selectedBillDate }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button
              v-if="status === 'N' || status === 'Y'"
              type="button"
              class="on"
              @click="saveConstruction"
            >
              저장
            </button>
            <button type="button" @click="goToConstructionList">목록</button>
            <button
              v-if="status === 'Y'"
              type="button"
              @click="CompleteConstruction"
            >
              공사완료
            </button>
            <button
              v-if="status === 'Y'"
              type="button"
              @click="checkWithdrawal"
            >
              수주철회
            </button>
            <button
              v-if="status === 'N' || status === 'Y'"
              type="button"
              @click="checkCancel"
            >
              수주취소
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
  <div
    id="Modal"
    class="d_pop"
    :style="{ display: isModalVisible ? 'block' : 'none' }"
    v-if="isModalVisible"
  >
    <div class="d_pop_box" style="width: 400px">
      <div class="d_pop_top">
        <div class="title">수주 취소 (마이즈가 취소하는 경우)</div>
        <a @click="hideModal">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>사유</th>
                <td>
                  <textarea
                    placeholder="상세 사유를 입력해주세요. "
                    v-model="cancelMemo"
                    ref="cancelMemo"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <button type="button" @click="CancelConstruction">확인</button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
  <div
    id="Modal2"
    class="d_pop"
    :style="{ display: isModalVisible2 ? 'block' : 'none' }"
    v-if="isModalVisible2"
  >
    <div class="d_pop_box" style="width: 400px">
      <div class="d_pop_top">
        <div class="title">수주 철회 (업체가 철회하겠다는 경우)</div>
        <a @click="hideModal2">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search seach_mobile">
          <table>
            <colgroup>
              <col style="width: 25%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>사유</th>
                <td>
                  <textarea
                    placeholder="상세 사유를 입력해주세요. "
                    v-model="withDrawalMemo"
                    ref="withDrawalMemo"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <button type="button" @click="saveWithdrawal">확인</button>
        <button type="button" @click="hideModal">취소</button>
      </div>
    </div>
  </div>
</template>

<script>
/* global daum */
import apiClient from "@/apiClient"; // 설정 파일에서 가져온 axios 인스턴스

export default {
  name: "ProjectView",
  data() {
    return {
      choiceSelDate: "", // 선택된 날짜 값
      selectedArea: "0", // 전체보기 기본 값 설정
      areas: [], // API에서 가져온 지역 목록을 저장할 배열
      materials: [], // 자재 목록을 저장할 배열
      selectedMaterial: "", // 선택된 자재를 저장할 변수
      selectedMaterials: [], // 선택된 자재 목록을 저장할 배열
      selectedStartDate: "", // 선택된 공사 시작일
      selectedStartDates: [], // 선택된 공사 시작일 목록
      postalCode: "", // 우편번호 입력값
      address: "", // 주소 입력값
      detailedAddress: "", // 상세주소 입력값
      salesManagers: [], // 영업 담당자 목록을 저장할 배열
      selectedSalesManager: "", // 선택된 영업 담당자
      pmManagers: [], // PM 담당자 목록을 저장할 배열
      selectedPmManager: "", // 선택된 PM 담당자
      extraAmount: 0, // 실제 금액을 저장하는 변수
      formattedExtraAmount: "", // ','가 포함된 금액을 저장하는 변수
      formattedRealAmount: "", // ','가 포함된 금액을 저장하는 변수

      selectedBillDate: "",
      totalPersonnel: "", // 총 투입 예정 인원수를 저장하는 변수
      representativeName: "", // 공사 담당자 이름을 저장하는 변수
      representativeContact: "", // 공사 담당자 연락처를 저장하는 변수 choiceDtm: "",
      choiceCompanyId: "",
      choiceCompanyName: "",
      choiceUserId: "",

      withDrawalDtm: "",
      withDrawalMemo: "",
      cancelDtm: "",
      cancelMemo: "",
      isModalVisible: false,

      isModalVisible2: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getUserid;
    },
    userName() {
      return this.$store.getters.getUsername;
    },
    userType() {
      return this.$store.getters.getUsertype;
    },
    userPermission() {
      return this.$store.getters.getUserpermission;
    },
  },
  methods: {
    showModal2() {
      this.isModalVisible2 = true;
    },
    hideModal2() {
      this.isModalVisible2 = false;
    },
    async saveWithdrawal() {
      try {
        // 데이터 저장을 위한 API 호출 로직
        const response = await apiClient.post(
          "/api/Construction/construction-withdrawal-update",
          {
            Id: this.id,
            withDrawalMemo: this.withDrawalMemo,
            LoginId: localStorage.getItem("userid"),
            // 필요한 다른 필드 추가
          }
        );

        if (response.status === 200) {
          alert("해당 공사건이 수주철회 상태로 저장되었습니다.");
          this.goToConstructionList(); // 목록으로 이동
          //this.$router.go(0); // 현재 페이지를 새로고침
        }
      } catch (error) {
        console.error("Error saving construction data:", error);
        alert("해당 공사건 수주철회 상태로 저장 중 오류가 발생했습니다.");
      }
    },
    async checkWithdrawal() {
      const confirmation = confirm("해당 공사를 수주 철회 하시겠습니까?");
      if (confirmation) {
        this.isModalVisible2 = true;
      }
    },
    getDayOfWeek(dateString) {
      console.log("Received dateString:", dateString);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        console.log("Invalid date:", dateString);
        return "유효하지 않은 날짜";
      }
      const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      const day = date.getDay();
      return daysOfWeek[day];
    },

    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
    // <!-- 공사건 상태 (수주전-'N'/수주완료-'Y'/공사완료-'YY'/계산서발급-'B'/수주철회-'F'/수주취소-'C') -->
    // 상태에 따른 문구 반환
    getStatusText(status) {
      // alert를 제거하고 콘솔로 상태를 로깅
      switch (status) {
        case "N":
          return "수주전";
        case "Y":
          return "수주완료";
        case "YY":
          return "공사완료";
        case "B":
          return "계산서발급";
        case "F":
          return "수주철회";
        case "C":
          return "수주취소";
        default:
          return "알 수 없음";
      }
    },
    getStatusClass(status) {
      // alert를 제거하고 콘솔로 상태를 로깅
      switch (status) {
        case "N":
          return "stat back_green"; // 수주전
        case "Y":
          return "stat back_blue"; // 수주완료
        case "YY":
          return "stat back_grey"; // 공사완료
        case "B":
          return "stat back_red"; // 계산서발급
        case "F":
          return "stat back_orange"; // 수주철회
        case "C":
          return "stat back_lightgrey"; // 수주취소
        default:
          return "stat back_default"; // 기본 값
      }
    },
    formatRealAmount(event) {
      // 입력된 값에서 숫자만 추출
      const rawValue = event.target.value.replace(/[^0-9]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.realAmount = parseInt(rawValue, 10) || 0;

      // 천 단위로 ',' 추가
      this.formattedRealAmount = this.realAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatExtraAmount(event) {
      let rawValue = event.target.value;

      // 입력 값이 '-'만 있으면 그대로 유지
      if (rawValue === "-") {
        this.formattedExtraAmount = rawValue;
        return;
      }

      // 입력된 값에서 숫자 및 음수 기호(-)만 추출
      rawValue = rawValue.replace(/[^0-9-]/g, "");

      // 숫자를 실제 금액 변수에 저장
      this.extraAmount = parseInt(rawValue, 10) || 0;

      // 음수 기호 유지한 상태로 천 단위로 ',' 추가
      this.formattedExtraAmount = this.extraAmount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    selectPmManager(pmmanagerId) {
      this.selectedPmManager = pmmanagerId;
    },
    async fetchPmManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/pm-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.pmManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.pmManagers);
        }
      } catch (error) {
        console.error("Error fetching pm managers:", error);
      }
    },
    selectSalesManager(salesmanagerId) {
      this.selectedSalesManager = salesmanagerId;
    },
    async fetchSalesManagers() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/User/sales-user-list",
          searchParam
        ); // 예시 API 엔드포인트
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.salesManagers = Array.isArray(data.data) ? data.data : [];
          console.log(this.salesManagers);
        }
      } catch (error) {
        console.error("Error fetching sales managers:", error);
      }
    },
    // 공사 시작일 추가
    addStartDate() {
      if (this.selectedStartDate) {
        // 이미 선택된 날짜가 있는지 확인
        const exists = this.selectedStartDates.includes(this.selectedStartDate);

        if (!exists) {
          // 선택된 날짜가 없으면 추가
          this.selectedStartDates.push(this.selectedStartDate);
        } else {
          alert("이미 선택된 공사 시작일입니다.");
        }

        // 선택 후 초기화
        this.selectedStartDate = "";
      }
    },
    // 공사 시작일 제거
    removeStartDate(index) {
      this.selectedStartDates.splice(index, 1);
    },
    async fetchMaterials() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };

        const response = await apiClient.post(
          "/api/Materials/all-materials-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.materials = Array.isArray(data.data) ? data.data : [];
          console.log(this.materials);
        }
      } catch (error) {
        console.error("Error fetching materials:", error);
      }
    },
    addMaterial() {
      if (this.selectedMaterial) {
        // 선택된 자재가 이미 목록에 없으면 추가
        const exists = this.selectedMaterials.find(
          (mat) => mat.id === this.selectedMaterial.id
        );
        if (!exists) {
          this.selectedMaterials.push({
            ...this.selectedMaterial,
            quantity: 1, // 기본 수량 1로 설정
          });
          // 선택 후 드롭다운 초기화
          this.selectedMaterial = "";
        } else {
          alert("이미 선택된 자재입니다.");
          // 선택 후 드롭다운 초기화
          this.selectedMaterial = "";
        }
      }
    },
    increaseQuantity(index) {
      this.selectedMaterials[index].quantity++;
    },
    decreaseQuantity(index) {
      if (this.selectedMaterials[index].quantity > 1) {
        this.selectedMaterials[index].quantity--;
      }
    },
    removeMaterial(index) {
      this.selectedMaterials.splice(index, 1);
    },
    handleAreaChange() {
      this.currentPage = 1; // 지역 변경 시 페이지를 1로 리셋
      //this.fetchData(); // 지역 선택 시 데이터 갱신
    },
    goToConstructionList() {
      this.$router.push({ path: "/projects/list" });
    },
    async fetchAreas() {
      try {
        const searchParam = {
          PageSize: 100,
          Page: "1",
        };
        const response = await apiClient.post(
          "/api/Area/all-area-list",
          searchParam
        );
        if (response.status === 200) {
          const data = JSON.parse(response.data);
          this.areas = Array.isArray(data.data) ? data.data : [];
          console.log(this.areas);
        }
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    },
    async billStatusConstruction() {
      // 사용자에게 계산서 발급 확인을 요청
      const userConfirmed = confirm("계산서 발급 확인 처리를 하시겠습니까?");

      // 사용자가 확인 버튼을 눌렀을 경우 API 호출
      if (userConfirmed) {
        try {
          // 계산서 발급 확인 위한 API 호출
          const response = await apiClient.post(
            "/api/Construction/construction-billstatus-update",
            {
              Id: this.id, // 공사 ID 전달
              Status: "B", // 공사완료 상태로 설정
              LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
            }
          );

          // 응답이 성공적일 경우
          if (response.status === 200) {
            alert("계산서 발급 확인이 처리되었습니다.");
            this.goToConstructionList(); // 공사 목록으로 이동
          } else {
            alert("계산서 발급 확인 처리 중 실패하였습니다.");
          }
        } catch (error) {
          console.error("Error during billstatus construction:", error);
          alert("오류가 발생했습니다. 계산서 발급 확인 처리가 실패했습니다.");
        }
      }
    },
    async checkCancel() {
      const confirmation = confirm("해당 공사를 취소 처리를 하시겠습니까?");
      if (confirmation) {
        this.isModalVisible = true;
      }
    },
    async CancelConstruction() {
      try {
        // 수주 취소 처리를 위한 API 호출
        const response = await apiClient.post(
          "/api/Construction/construction-cancel-update",
          {
            Id: this.id, // 공사 ID 전달
            Status: "C", // 공사완료 상태로 설정
            cancelMemo: this.cancelMemo,
            LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
          }
        );

        // 응답이 성공적일 경우
        if (response.status === 200) {
          alert("공사 수주 취소 처리되었습니다.");
          this.goToConstructionList(); // 공사 목록으로 이동
        } else {
          alert("공사 수주 취소 처리에 실패하였습니다.");
        }
      } catch (error) {
        console.error("Error during cancel construction:", error);
        alert("오류가 발생했습니다. 공사 수주 취소 처리가 실패했습니다.");
      }
    },
    async CompleteConstruction() {
      // 사용자에게 공사 완료 확인을 요청
      const userConfirmed = confirm("공사 완료 처리를 하시겠습니까?");

      // 사용자가 확인 버튼을 눌렀을 경우 API 호출
      if (userConfirmed) {
        try {
          // 공사 완료 처리를 위한 API 호출
          const response = await apiClient.post(
            "/api/Construction/construction-complete",
            {
              Id: this.id, // 공사 ID 전달
              Status: "YY", // 공사완료 상태로 설정
              LoginId: localStorage.getItem("userid"), // 로그인 사용자 ID
            }
          );

          // 응답이 성공적일 경우
          if (response.status === 200) {
            alert("공사가 완료 처리되었습니다.");
            this.goToConstructionList(); // 공사 목록으로 이동
          } else {
            alert("공사 완료 처리에 실패하였습니다.");
          }
        } catch (error) {
          console.error("Error during complete construction:", error);
          alert("오류가 발생했습니다. 공사 완료 처리가 실패했습니다.");
        }
      }
    },
    async saveConstruction() {
      if (this.checkFields()) {
        // 공사 시작일을 '^'로 구분된 문자열로 변환
        const formattedStartDates = this.selectedStartDates.join("^");

        // status가 'Y'일 때 확인 메시지 표시
        if (this.status === "Y") {
          const userConfirmed = confirm(
            "해당 공사건은 수주완료 인 상태입니다. \n수주업체에게 변경된 정보 공유하셔야 합니다. \n계속하시겠습니까?"
          );
          if (!userConfirmed) {
            return; // 사용자가 취소를 누르면 저장을 중단
          }
        }

        try {
          const response = await apiClient.post(
            "/api/Construction/construction-edit",
            {
              Id: this.id,
              ProjectName: this.projectName,
              Price: this.formattedRealAmount,
              AddPrice: this.formattedExtraAmount,
              StartDate: formattedStartDates,
              AreaId: this.selectedArea,
              ZipCode: this.postalCode,
              Addr1: this.address,
              Addr2: this.detailedAddress,
              DetailContent: this.detailDesc,
              Materials: this.selectedMaterials.map((mat) => ({
                MaterialId: mat.id,
                MaterialCnt: mat.quantity,
              })),
              EtcMemo: this.addMemo,
              MizeSalesUserId: this.selectedSalesManager,
              MizePMUserId: this.selectedPmManager,
              CustomerName: this.customerName,
              LoginId: localStorage.getItem("userid"),
              // 필요한 다른 필드 추가
            }
          );
          if (response.status === 200) {
            alert("공사 정보가 성공적으로 수정되었습니다.");
            this.goToConstructionList();
          }
        } catch (error) {
          console.error("Error saving construction data:", error);
          alert("공사 정보가 수정 중 오류가 발생했습니다.");
        }
      }
    },
    async fetchConstructionData() {
      try {
        this.isFetchingData = true; // 데이터 불러오기 시작
        const response = await apiClient.post(
          "/api/Construction/construction-info",
          {
            Id: this.id,
            LoginId: localStorage.getItem("userid"),
          }
        );

        if (response.status === 200) {
          const data = JSON.parse(response.data);
          console.log(data);

          this.constructionInfo = Array.isArray(data.data) ? data.data : [];
          console.log(
            "☆☆☆☆☆☆☆☆this.constructionInfo[0]☆☆☆☆☆☆☆☆☆" +
              this.constructionInfo[0]
          );

          this.status = this.constructionInfo[0].status; // 추가: 상태값 저장
          this.projectName = this.constructionInfo[0].projectName;
          this.formattedRealAmount = this.constructionInfo[0].price;
          this.formattedExtraAmount =
            this.constructionInfo[0].addPrice == "0"
              ? ""
              : this.constructionInfo[0].addPrice;
          this.selectedArea = this.constructionInfo[0].areaId;
          // 시작일 부분 split 해서 ui 표현해야 함
          this.formattedStartDates = this.constructionInfo[0].startDate;
          this.selectedStartDates = this.formattedStartDates.split("^"); // 날짜를 배열로 변환

          this.postalCode = this.constructionInfo[0].zipCode;
          this.address = this.constructionInfo[0].addr1;
          this.detailedAddress = this.constructionInfo[0].addr2;
          this.detailDesc = this.constructionInfo[0].detailContent;

          this.choiceSelDate = data.data[0].choiceSelDate; // 공사 시작일 선택 값 설정
          //this.selectedMaterials = this.constructionInfo[0].

          this.addMemo = this.constructionInfo[0].etcMemo;
          this.selectedSalesManager = this.constructionInfo[0].mizeSalesUserId;
          this.selectedPmManager = this.constructionInfo[0].mizePMUserId;
          this.customerName = this.constructionInfo[0].customerName;
          this.totalPersonnel = this.constructionInfo[0].totalPeople;
          this.representativeName = this.constructionInfo[0].managerUserName;
          this.representativeContact =
            this.constructionInfo[0].managerUserPhone;

          this.selectedBillDate = this.constructionInfo[0].billDate;
          this.choiceDtm = this.constructionInfo[0].choiceDtm;
          this.choiceCompanyId = this.constructionInfo[0].choiceCompanyId;
          this.choiceCompanyName = this.constructionInfo[0].choiceCompanyName;
          this.choiceUserId = this.constructionInfo[0].choiceUserId;

          this.withDrawalDtm = this.constructionInfo[0].withDrawalDtm;
          this.withDrawalMemo = this.constructionInfo[0].withDrawalMemo;

          this.cancelDtm = this.constructionInfo[0].canceledDtm;
          this.cancelMemo = this.constructionInfo[0].cancelMemo;

          // 공사 정보에 자재 목록이 포함되어 있는 경우 처리
          if (this.constructionInfo[0].materials) {
            this.selectedMaterials = this.constructionInfo[0].materials.map(
              (material) => ({
                id: material.materialId,
                materialsName: material.materialName,
                modelName: material.modelName || "", // 모델명이 있을 경우 포함
                quantity: material.materialCnt || 1, // 수량 정보가 없으면 기본 수량 1
              })
            );
          }
          // console.log(this.meetingroomInfo[0].timeSettings);
        }
      } catch (error) {
        console.error("Error fetching meetingroomInfo data:", error);
      } finally {
        this.isFetchingData = false; // 데이터 불러오기 완료
      }
    },
    checkFields() {
      if (!this.projectName) {
        alert("프로젝트명을 입력해주세요");
        this.$refs.projectName.focus();
        return false;
      }
      if (!this.formattedRealAmount) {
        alert("공사금액을 입력해주세요");
        this.$refs.formattedRealAmount.focus();
        return false;
      }
      if (this.selectedArea === "0") {
        // 전체보기 (기본값) 또는 선택되지 않은 상태
        alert("지역을 선택해주세요");
        return false;
      }
      if (this.selectedStartDates.length === 0) {
        // 공사 시작일이 선택되지 않았을 때
        alert("공사 시작일을 선택해주세요");
        return false;
      }

      if (this.selectedMaterials.length === 0) {
        // 투입자재목록이 선택되지 않았을 때
        alert("투입자재목록을 선택해주세요");
        return false;
      }

      if (!this.selectedSalesManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        alert("담당자(영업)를 선택해주세요");
        return false;
      }

      if (!this.selectedPmManager) {
        // 담당자(영업) 항목이 선택되지 않았을 때
        alert("담당자(PM)를 선택해주세요");
        return false;
      }
      if (!this.customerName) {
        // 고객사 상호가 입력되지 않았을 때
        alert("고객사 상호를 입력해주세요");
        this.$refs.customerName.focus();
        return false;
      }

      if (!this.detailedAddress) {
        // 상세주소가 입력되지 않았을 때
        alert("상세주소를 입력해주세요");
        this.$refs.detailedAddress.focus();
        return false;
      }

      return true;
    },
    openPostcodePopup() {
      new daum.Postcode({
        oncomplete: (data) => {
          this.postalCode = data.zonecode;
          this.address = data.address;
          this.$refs.detailedAddress.focus();
        },
      }).open();
    },
  },
  mounted() {
    this.fetchAreas(); // 컴포넌트가 마운트될 때 회사 정보를 가져옵니다.
    this.fetchMaterials(); // 자재 데이터를 가져오기
    this.fetchSalesManagers(); // 영업 담당자 목록을 가져옴
    this.fetchPmManagers(); // 영업 담당자 목록을 가져옴
    this.fetchConstructionData(); // 공사 상세정보
  },
};
</script>

<style scoped>
/* 기본 입력 필드 오른쪽 정렬 */
input.amount-input {
  text-align: right; /* 입력값은 오른쪽 정렬 */
}

/* placeholder 왼쪽 정렬 */
input.amount-input::placeholder {
  text-align: left; /* placeholder는 왼쪽 정렬 */
}
</style>
