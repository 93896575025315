<template>
  <div class="sso-login-container">
    <p>SSO 로그인 처리 중...</p>
  </div>
</template>

<script>
import apiClient from "@/apiClient"; // 로그인 처리를 위한 API 클라이언트

export default {
  name: "SSOLogin",
  async created() {
    const encodedUserid = this.$route.params.userid; // URL 파라미터로 받은 userid

    // type = 'A' 면 공사등록을 경우 list 로 들어오는 경우
    // type = 'B' 면 세금계산서 관련
    // type = 'N' 면 일반 마이즈 직원 접속 시

    const type = this.$route.params.type;
    const conid = this.$route.params.conid;

    console.log("type===" + type);
    console.log("conid===" + conid);

    if (encodedUserid) {
      try {
        // userid를 사용한 로그인 처리 (예시)
        const response = await apiClient.post("/api/Auth/sso-login", {
          UserId: encodedUserid,
          LoginType: type,
        });

        if (response.status === 200) {
          // 로그인 성공 시 로컬 스토리지에 사용자 정보 저장
          const user = response.data.user;
          localStorage.setItem("userid", user.UserId);
          localStorage.setItem("usertype", user.UserType);
          localStorage.setItem("username", user.AdminName);
          localStorage.setItem("companyid", user.CompanyId);
          localStorage.setItem("userpermission", user.UserPermission);

          this.$store.dispatch("updateUserName", user.AdminName); // Vuex 스토어와 localStorage에 저장
          this.$store.dispatch("updateUserType", user.UserType); // Vuex 스토어와 localStorage에 저장

          // 외주업체라면
          if (localStorage.getItem("usertype") == "C" && type === "A") {
            console.log("여기===" + type);
            this.$router.push("/projects/list");
          }
          // 마이즈 라면
          if (localStorage.getItem("usertype") == "M" && type === "B") {
            console.log("여기여기===" + type);
            this.$router.push("/projects/view/" + conid);
          }
          // 마이즈 라면
          if (localStorage.getItem("usertype") == "M" && type === "N") {
            console.log("여기여기여기===" + type);
            this.$router.push("/projects/list");
          }
        }
      } catch (error) {
        console.error("SSO 로그인 처리 중 오류:", error);
        alert("로그인에 실패했습니다.");
        this.$router.push("/login"); // 실패 시 로그인 페이지로 리디렉션
      }
    } else {
      alert("유효한 사용자 정보가 없습니다.");
      this.$router.push("/login"); // userid가 없으면 로그인 페이지로 리디렉션
    }
  },
};
</script>

<style scoped>
.sso-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  font-weight: bold;
}
</style>
