import axios from "axios";

// axios 인스턴스 생성
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // .env 파일에서 API 기본 URL을 가져옴
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
